<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import productsTable from './components/productsTable';

export default {
  page: {
    title: "Produits",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, productsTable },
  data() {
    return {
      tableData: {},
      title: "Produits",
      items: [
        {
          text: "Master Data",
          to: {name:"master_data"},
        },
        {
          text: "Référentiel",
          to: {name:"master_data.referentiels"},
        },
        {
          text: "Gestion de Stock",
          to: {name:"master_data.referentiels.stock_management"},
        },
        {
          text: "Produits",
          active: true
        }
      ],
      
      fields: [
        { key: "name", sortable: true },
        { key: "address", sortable: true },
        { key: "analytic_account", sortable: true },
      ]
    };
  },
  
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <products-table/>
  </Layout>
</template>